.container {
  color: white;
  margin-top: 56px;
  width: 100%;
  background-color: var(--color-b01);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 24px;
  margin: auto;
  width: 100%;
  max-width: 1200px;
}

.linkContainer {
  display: flex;
  gap: 24px;
  overflow: hidden;
}

.linkSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 282px;
}

.linkSection a {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.linkSection a:hover {
  color: #ff3e99;
}

.linkHeader {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.getAtmosfy {
  color: #ff3e99;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

.getAtmosfy:hover {
  color: white;
}

.socials {
  width: 282px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;
}

.logo {
  height: 40px;
  width: auto;
}

.copyrightText {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.externalSocial {
  display: flex;
  gap: 16px;
  height: 24px;
}

.externalSocial img {
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    margin-top: 40px;
  }

  .linkContainer {
    flex-direction: column;
    gap: 32px;
  }

  .linkSection {
    gap: 12px;
  }

  .socials {
    gap: 32px;
    align-items: flex-start;
  }
}
