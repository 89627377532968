.container {
  width: 100%;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  display: flex;
  gap: 24px;
  background-color: var(--color-bs01);
  height: 72px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  color: var(--color-w01);
  backdrop-filter: blur(12px);
}

.contentContainer {
  width: 943px;
}

.logo {
  width: 40px;
  height: 40px;
}

.text {
  margin: 0;
}
