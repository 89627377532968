@import-normalize; /* import normalize.css styles */
@import "~video-react/dist/video-react.css";

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("opentype");
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src:
    url("https://use.typekit.net/af/79862c/00000000000000007735e60e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/79862c/00000000000000007735e60e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/79862c/00000000000000007735e60e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
}

/* base styles */
:root {
  --color-b01: #04060C;
  --color-b02: #000;
  --color-b03: #242424;
  --color-b04: #2D3033;
  --color-bs01: rgba(0, 0, 0, 0.8);
  --color-bs02: rgba(0, 0, 0, 0.6);
  --color-bs025: rgba(0, 0, 0, 0.5);
  --color-bs03: rgba(0, 0, 0, 0.4);
  --color-bs035: rgba(0, 0, 0, 0.3);
  --color-bs04: rgba(0, 0, 0, 0.2);
  --color-bs05: rgba(0, 0, 0, 0.1);
  --color-bs06: rgba(0, 0, 0, 0);
  --color-g00: #0D0F15;
  --color-g01: #1A1D22;
  --color-g02: #2F3437;
  --color-g03: #525A60;
  --color-g04: #75818A;
  --color-g05: #9FA7AD;
  --color-g06: #C8CDD0;
  --color-g07: #E3E6E8;
  --color-g08: #F1F2F4;
  --color-i01: #C5CEE0;
  --color-n00: #0D0F15;
  --color-n01: #2F3437;
  --color-n02: #525A60;
  --color-n03: #75818A;
  --color-n04: #9FA7AD;
  --color-n05: #C8CDD0;
  --color-n06: #E3E6E8;
  --color-n07: #F1F2F4;
  --color-n08: #BFBFBF;
  --color-p01: #FF3E99;
  --color-p02: #812252;
  --color-p03: #E6007E;
  --color-w01: #FFFFFF;
  --color-ws01: rgba(255, 255, 255, 0.8);
  --color-ws02: rgba(255, 255, 255, 0.6);
  --color-ws03: rgba(255, 255, 255, 0.4);
  --color-ws04: rgba(255, 255, 255, 0.2);
  --color-ws05: rgba(255, 255, 255, 0.1);
  --color-cr01: #EE031C;
  --color-y01: #FFC01D;
  --color-green: #049F2F;
  --color-banner-green: #44C030;
  --color-sage: #93CB9A;
  --color-caramel: #E58204;
  --color-skyBlue: #C5CEE0;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
}

/* disable address bar hiding on scroll */
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
}

html.scrollable,
body.scrollable {
  overflow-y: auto;
}

body {
  font-family: proxima-nova, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 24px;
  background: #0d0f15;
  margin: 0;
}

a {
  text-decoration: none;
}

/* typography */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
}

h1 {
  font-size: 48px;
  line-height: 56px;
}

h2 {
  font-size: 36px;
  line-height: 44px;
}

h3 {
  font-size: 28px;
  line-height: 32px;
}

h4 {
  font-size: 24px;
  line-height: 28px;
}

h5 {
  font-size: 19px;
  line-height: 24px;
}

h6 {
  font-size: 17px;
  line-height: 24px;
}

p {
  margin: 0;
}

/* atmosfy type styles */

.body__medium {
  font-weight: 500;
}

.body__bold {
  font-weight: 700;
}

.body__tight {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.03em;
}

.body__tight-medium {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.04em;
}

.body__tight-bold {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.subtext {
  font-size: 14px;
  line-height: 20px;
}

.subtext__medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.subtext__semi-bold {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.subtext__bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.footnote {
  font-size: 12px;
  line-height: 16px;
}

.footnote__bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

/* ui elements */

button {
  border: 0;
  background: none;
  display: block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.button__cta {
  color: var(--color-w01);
  background-color: var(--color-p01);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;
  padding: 12px;
}

.button__dismiss {
  color: var(--color-p01);
}

.button__label {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
}

.button__label-tight {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

/* media */

img {
  width: 100%;
  height: auto;
}

/* elements */

.loading_container {
  background: var(--color-n02);
  width: 100vw;
  height: 100vh;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading p {
  color: var(--color-w01);
  text-align: center;
}

.title__h1 {
  font-size: 22px;
  line-height: 28px;
  margin: 0;
}

.title__h1_color {
  color: var(--color-p01) !important;
}

.title_h5 {
  font-size: 19px;
  line-height: 24px;
  margin: 0;
}
.title__h5_color {
  color: var(--color-w01);
}

.div_p {
  margin: 0 0 10px 0;
  text-align: center;
}
.div_p_span {
  display: inline;
}

.div_p_span.location-profile__open {
  color: #049f2f;
  font-weight: bold;
}

.div_p_span.location-profile__closed {
  color: #ee031c;
  font-weight: bold;
}
