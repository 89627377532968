.container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.logo {
  height: 40px;
  width: auto;
}

.contentText {
  margin-top: 20px;
  color: var(--color-w01);
  text-align: center;
}

.retryButton {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--color-p01);
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;
}

a:link, a:visited {
  color: var(--color-w01);
  text-decoration: none;
}