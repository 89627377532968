.container {
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 80px;
  display: flex;
  justify-content: center;
  background-color: var(--color-banner-green);
  align-items: center;
  padding: 10px 0;
}

.contentContainer {
  display: flex;
  gap: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  max-width: 1200px;
}

.text {
  color: var(--color-w01);
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
}

.claimBusinessButton {
  background-color: var(--color-w01);
  padding: 8px 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.linkText {
  color: var(--color-b01);
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 960px) {
  .container {
    top: 72px;
  }
}
