.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 122px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: var(--color-b01);
  color: var(--color-w01);
  z-index: 999;
}

.menuIcon {
  display: none;
}

.nav > ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 80px;
  list-style-type: none;
  padding: 0px;
}

.nav > ul > li {
  color: var(--color-w01);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  cursor: pointer;
}

.nav > ul > li:hover {
  color: var(--color-p01);
}

.logo {
  width: auto;
  height: 32px;
  cursor: pointer;
}

.buttonGetAtmosfy {
  background-color: var(--color-p01);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  min-height: 40px;
}

.buttonGetAtmosfy:hover {
  background-color: var(--color-w01);
}

.buttonGetAtmosfy div {
  width: 100%;
  display: block;
  color: var(--color-w01);
  text-decoration: none;
  padding: 6px 28px;
}

.buttonGetAtmosfy div:hover {
  color: var(--color-p01);
}

@media (max-width: 1165px) {
  .nav > ul {
    gap: 40px;
  }
}

@media (max-width: 1010px) {
  .nav > ul {
    gap: 25px;
  }
  .header {
    gap: 50px;
  }
}

@media (max-width: 768px) {
  .header {
    height: 72px;
  }
  .nav {
    position: absolute;
    top: 72px;
    left: 0;
    height: calc(100vh - 72px);
    width: 100%;
    background-color: var(--color-b01);
  }
  .nav > ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    margin-top: 50px;
    margin-left: 24px;
  }
  .nav > ul > li {
    color: var(--color-w01);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; /* 133.333% */
  }
  .menuIcon {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    left: 24px;
    cursor: pointer;
  }
  .buttonGetAtmosfy {
    display: none;
  }
}
