.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  transition: opacity 300ms;
  pointer-events: none;
  opacity: 0;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal__background {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal__content {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 50px 20px 20px;
  border-radius: 16px 16px 0 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 300ms;
  transform: translate(0, 100%);
}

.modal__description {
  color: var(--color-n01);
}

.button__not-now {
  font-size: 17px;
  color: var(--color-b01);
}

@media (min-width: 768px) {
  .modal__content {
    position: relative;
    width: 375px;
    height: 260px;
    top: 50%;
    left: 50%;
    margin-top: -130px;
    margin-left: -190px;
    border-radius: 16px;
  }
}

.modal .modal__content {
  transform: translate(0);
}

.modal__logo {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -28px;
  margin-top: -28px;
}

.modal__friends_logo {
  width: 160px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -32px;
  margin-left: -80px;
}

.modal p {
  margin-bottom: 30px;
  text-align: center;
}

/* css added for new variant modals */
.detail {
  margin: 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.detail > div {
  background: url(../../images/seperator.png) no-repeat;
  background-position: right center;
  text-align: center;
  padding: 5px 9px;
}
.detail > div:last-child {
  background: none;
}
.detail .title {
  text-align: center;
  font-size: 12px;
  color: #b8b8b8;
}
.detail .value {
  text-align: center;
  font-size: 20px;
}
.detail .title .star {
  display: inline-block;
  margin: 0 0px;
}
.detail .title .star img {
  width: 15px;
}

.modal > p > a {
  color: #e13788;
  text-decoration: none;
}

.modal_black_background {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #ffffff;
}
