.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 24px 24px 40px 24px;
  width: 459px;
  height: 556px;
  max-width: 80vw;
  color: var(--color-b02);
  border-radius: 8px;
  border: 1px solid var(--color-n06);
  background: var(--color-w01);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
}

.headerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  width: 300px;
  margin: auto;
}

.closeIconContainer {
  align-self: flex-end;
}

.closeIcon {
  cursor: pointer;
}

.qrCodeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.qrCode {
  width: 220px;
  height: 220px;
}

.downloadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.downloadIconsContainer {
  display: flex;
  gap: 12px;
}